import axios from "axios";
import router from "@/router";
import baseUrl from '@/api/http/baseUrl'
import {
    Message
} from "element-ui";

const http = axios.create({
    baseURL: '/apiVis'
});

let cancel = {}

const cancelToken = axios.CancelToken;

function getKey(config) {
    return config.url + config.cancelKey;
}
http.interceptors.request.use(
    config => {
        if (config.method == 'get') {
            // 判断前一次请求是否取消
            if (cancel[getKey(config)]) {
                cancel[getKey(config)].cancel();
                cancel[getKey(config)] = null;
            }
            // 初次请求设置请求取消的token
            cancel[getKey(config)] = cancelToken.source();
            config['cancelToken'] = cancel[getKey(config)].token;
        }
        return config;
    }, err => {
        Promise.error(err);
    }
)

http.interceptors.response.use(
    res => {
        cancel[getKey(res.config)] = null;
        if (res.status === 200) {
            if (res.data.Message == 'Login is needed') {
                // router.push({
                //     name: 'login'
                // })
            }
            return Promise.resolve(res);
        } else {
            return Promise.reject(res);
        }
    },
    err => {
        const {
            response
        } = err;
        if (response) {
            errorHandle(response.status, response.data.message);
            return Promise.reject(response);
        } else {
            if (err.name == 'CanceledError') return new Promise(() => {});
            Message.error("网络中断");
        }
    }
)

function errorHandle(statue, message) {
    // 状态码判断
    switch (statue) {
        case 401:
            Message.error("错误1");
            break;
        case 402:
            Message.error("错误2");
            break;
        default:
            Message.error(message);
    }
}

function get(url, params = null, key = '') {
    let config = {
        cancelKey: key
    };
    if (params) config.params = params;
    return http.get(url, config);
}

function post(url, params) {
    return http.post(url, params);
}

function postJson(url, params) {
    return http.post(url, params, {
        headers: {
            'content-type': 'application/json'
        }
    })
}

export default {
    http,
    get,
    post,
    postJson
};