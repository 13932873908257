<template>
  <!-- 页面整体布局 -->
  <div :class="classObj" class="app-wrapper">
    <!-- 左侧导航栏 -->
    <sidebar class="sidebar-container"></sidebar>

    <div class="main-container">
      <div class="fixed-header">
        <!-- 头部工具栏 -->
        <navbar></navbar>
        <!-- 标签栏 -->
        <tags-view></tags-view>
      </div>
      <!--主窗口 -->
      <app-main></app-main>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'
  import {
    AppMain,
    Navbar,
    Sidebar,
    TagsView
  } from './components'

  export default {
    name: 'Layout',
    data() {
      return {

      }
    },
    created() {
      this.getFilterOptions();  
    },
    methods: {
      getFilterOptions() {
        this.$api.schoolCustom.getFilterOptions().then(res => {
          if (res.data.code = 200) {
            // this.options = res.data.data;
          }
        }).catch(err => {
          this.$message.warning("请求出错");
        })
      }
    },
    computed: {
      ...mapGetters([
        'sidebar'
      ]),
      classObj() {
        return {
          hideSidebar: !this.sidebar.opend,
          openSidebar: this.sidebar.opend
        }
      }
    },
    components: {
      AppMain,
      Navbar,
      Sidebar,
      TagsView
    },
  }
</script>

<style lang='scss' scoped>
  @import "~@/styles/variables.scss";

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }
</style>