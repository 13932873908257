import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/layout'


// 定义静态路由
export const staticRoutes = [{
    path: '/',
    redirect: '/login',
    hidden: true
},
    {
        path: '/login',
        name: 'login',
        component: () => import("@/views/login/login"),
        hidden: true
    }, {
        path: '/monitor',
        name: 'monitor',
        component: () => import('@/views/monitor/Monitor'),
        hidden: true
    },
    // {
    //     path: '/test',
    //     name: 'test',
    //     component: () => import('@/views/test/test.vue'),
    //     meta: {
    //         title: '测试',
    //         icon: 'el-icon-s-promotion'
    //     }
    // },
]

// 异步路由
export const asyncRoutes = [
    {
        path: '/sending',
        component: Layout,
        name: 'sending',
        // redirect: '/sending/p2p',
        // alwaysShow: true,
        // meta: {
        //   title: '送审管理',
        //   icon: 'el-icon-s-promotion'
        // },
        children: [{
            path: 'p2p',
            name: 'p2p',
            component: () => import('@/views/sending/p2p'),
            meta: {
                title: '送审管理',
                icon: 'el-icon-s-promotion'
            }
        },
            // {
            //   path: 'p2f',
            //   name: 'p2f',
            //   component: () => import('@/views/sending/p2f'),
            //   meta: {
            //     title: '点对面',
            //     // icon: 'el-icon-s-help'
            //   }
            // },
            {
                path: 'sendRecord/:id',
                name: 'sendRecord',
                component: () => import('@/views/sending/sendRecord'),
                meta: {
                    title: '送审记录',
                    // icon: 'el-icon-s-help'
                    activeMenu: '/sending/p2p'
                },
                hidden: true
            }
        ]
    },
    {
        path: '/reviewCheck',
        component: Layout,
        name: 'reviewCheck',
        // redirect: '/schoolCustom/index',
        meta: {
            title: '审核管理',
            icon: 'el-icon-star-on'
        },
        children: [{
            path: 'index',
            name: 'reviewCheck_index',
            component: () => import('@/views/reviewCheck/reviewCheck'),
            meta: {
                title: '评阅审核',
                // icon: 'el-icon-star-on'
            }
        }, {
            path: 'schoolPublic',
            name: 'schoolPublic',
            component: () => import('@/views/reviewCheck/schoolPublic'),
            meta: {
                title: '院校发布',
                // icon: 'el-icon-school'
            }
        }

        ]
    },
    {
        path: '/batchManagement',
        component: Layout,
        name: 'batchManagement',
        // redirect: '/schoolCustom/index',
        meta: {
            title: '批次管理',
            icon: 'el-icon-s-management'
        },
        children: [{
            path: 'fileManagement',
            name: 'fileManagement',
            component: () => import('@/views/batchManagement/fileManagement'),
            meta: {
                title: '归档管理',
                // icon: 'el-icon-school'
            }
        },

            {
                path: 'fileQuerry',
                name: 'fileQuerry',
                component: () => import('@/views/batchManagement/fileQuerry_v2'),
                meta: {
                    title: '归档查询',
                    // icon: 'el-icon-s-help'
                }
            },
        ]
    },
    {
      path: '/workManagement',
      component: Layout,
      name: 'workManagement',
      meta: {
        title: '工作管理',
        icon: 'el-icon-s-management'
      },
      children: [{
        path: 'batchDistribution',
        name: 'batchDistribution',
        component: () => import('@/views/workManagement/batchDistribution'),
        meta: {
          title: '批次分配'
        }
      },
      {
        path: 'taskManagement',
        name: 'taskManagement',
        component: () => import('@/views/workManagement/taskManagement'),
        meta: {
          title: '任务管理'
        }
      },
      {
        path: 'workLogs',
        name: 'workLogs',
        component: () => import('@/views/workManagement/workLogs'),
        meta: {
          title: '工作日志'
        }
      }]
    },
    {
        path: '/reviewCustom',
        component: Layout,
        name: 'reviewCustom',
        // redirect: '/schoolCustom/index',
        meta: {
            title: '评阅定制',
            icon: 'el-icon-notebook-2'
        },
        children: [{
            path: 'ExpertReviewCustom',
            name: 'ExpertReviewCustom',
            component: () => import('@/views/reviewCustom/ExpertReviewCustom'),
            meta: {
                title: '专家评阅定制',
                // icon: 'el-icon-notebook-2'
            }
        },
            {
                path: 'reviewBookCustom',
                name: 'reviewBookCustom',
                component: () => import('@/views/reviewCustom/ReviewBookCustom'),
                meta: {
                    title: '评阅书模板',
                    // icon: 'el-icon-notebook-2'
                }
            },
            {
                path: 'templateCustom/:id',
                name: 'templateCustom',
                component: () => import('@/views/reviewCustom/TemplateCustom'),
                meta: {
                    title: '模板定制',
                    // icon: 'el-icon-notebook-2'
                    activeMenu: '/reviewCustom/reviewBookCustom'
                },
                hidden: true
            }
        ]
    },
    {
        path: '/schoolCustom',
        component: Layout,
        name: 'schoolCustom',
        // redirect: '/schoolCustom/index',
        meta: {
            title: '院校定制',
            icon: 'el-icon-s-custom'
        },
        children: [{
            path: 'index',
            name: 'schoolCustom_index',
            component: () => import('@/views/schoolCustom'),
            meta: {
                title: '院校定制',
                icon: 'el-icon-s-home'
            }
        },
            {
                path: 'ruleCustom/:id',
                name: 'ruleCustom',
                component: () => import('@/views/schoolCustom/ruleCustom'),
                meta: {
                    title: '规则定制',
                    icon: 'el-icon-s-help',
                    activeMenu: '/schoolCustom/index'
                },
                hidden: true
            },
            {
                path: 'manageCustom/:id',
                name: 'manageCustom',
                component: () => import('@/views/schoolCustom/manageCustom'),
                meta: {
                    title: '管理定制',
                    icon: 'el-icon-s-help',
                    activeMenu: '/schoolCustom/index'
                },
                hidden: true
            }
        ]
    },
    // {
    //   path: '/authority',
    //   component: Layout,
    //   name: 'authority',
    //   // redirect: '/schoolCustom/index',
    //   meta: {
    //     title: '权限管理',
    //     icon: 'el-icon-s-cooperation'
    //   },
    //   children: [{
    //       path: 'strategy',
    //       name: 'strategy',
    //       component: () => import('@/views/authority/Strategy'),
    //       meta: {
    //         title: '权限策略',
    //         // icon: 'el-icon-s -help'
    //       }
    //     },
    //     {
    //       path: 'insertStrategy',
    //       name: 'insertStrategy',
    //       component: () => import('@/views/authority/InsertStrategy'),
    //       meta: {
    //         title: '创建权限策略',
    //         // icon: 'el-icon-s-help'
    //       }
    //     }
    //   ]
    // },
    {
        path: '/financial',
        component: Layout,
        name: 'financial',
        // redirect: '/schoolCustom/index',
        meta: {
            title: '财务管理',
            icon: 'el-icon-s-ticket'
        },
        children: [{
            path: 'unpay',
            name: 'unpay',
            component: () => import('@/views/financial/unpay'),
            meta: {
                title: '未打款',
                // icon: 'el-icon-s -help'
            }
        },
            {
                path: 'payed',
                name: 'payed',
                component: () => import('@/views/financial/payed'),
                meta: {
                    title: '已打款',
                    // icon: 'el-icon-s -help'
                }
            },
            {
                path: 'paymentFailed',
                name: 'paymentFailed',
                component: () => import('@/views/financial/paymentFailed'),
                meta: {
                    title: '打款失败',
                    // icon: 'el-icon-s -help'
                }
            },
        ]
    },
    {
        path: '/account',
        component: Layout,
        name: 'account',
        // redirect: '/schoolCustom/index',
        meta: {
            title: '权限管理',
            icon: 'el-icon-user-solid'
        },
        children: [
            // {
            //   path: 'admin',
            //   name: 'adminAccount',
            //   component: () => import('@/views/account/AdminAccount'),
            //   meta: {
            //     title: '管理账号',
            //     // icon: 'el-icon-s-help'
            //   }
            // },
            {
                path: 'school',
                name: 'schoolAccount',
                component: () => import('@/views/account/SchoolAccount'),
                meta: {
                    title: '院校账号',
                    // icon: 'el-icon-s-help'
                }
            },
            // {
            //   path: 'student',
            //   name: 'studentAccount',
            //   component: () => import('@/views/account/StudentAccount'),
            //   meta: {
            //     title: '学生账号',
            //     // icon: 'el-icon-s -help'
            //   }
            // },
            // {
            //   path: 'tutor',
            //   name: 'tutorAccount',
            //   component: () => import('@/views/account/TutorAccount'),
            //   meta: {
            //     title: '导师账号',
            //     // icon: 'el-icon-s -help'
            //   }
            // },
            {
                path: 'expert',
                name: 'expertAccount',
                component: () => import('@/views/account/ExpertAccount'),
                meta: {
                    title: '专家账号',
                    // icon: 'el-icon-s -help'
                }
            },
            {
                path: 'strategy',
                name: 'strategy',
                component: () => import('@/views/authority/Strategy'),
                meta: {
                    title: '用户管理',
                    // icon: 'el-icon-s -help'
                }
            },
            {
                path: 'insertStrategy',
                name: 'insertStrategy',
                component: () => import('@/views/authority/InsertStrategy'),
                meta: {
                    title: '角色管理',
                    // icon: 'el-icon-s-help'
                }
            }
            ,
        ]
    },
    {
        path: '/basicInfo',
        component: Layout,
        name: 'basicInfo',
        // redirect: '/schoolCustom/index',
        meta: {
            title: '基础信息',
            icon: 'el-icon-document'
        },
        children: [{
            path: 'college',
            name: 'collegeInfo',
            component: () => import('@/views/basicInfo/CollegeInfo'),
            meta: {
                title: '院校信息',
                // icon: 'el-icon-s-help'
            }
        },
            // {
            //   path: 'degree',
            //   name: 'degreeInfo',
            //   component: () => import('@/views/basicInfo/degreeInfo'),
            //   meta: {
            //     title: '学位信息',
            //     // icon: 'el-icon-s-help'
            //   }
            // },
            {
                path: 'subject',
                name: 'subjectInfo',
                component: () => import('@/views/basicInfo/SubjectInfo'),
                meta: {
                    title: '学科信息',
                    // icon: 'el-icon-s -help'
                }
            },
            {
                path: 'expert',
                name: 'expertInfo',
                component: () => import('@/views/basicInfo/ExpertInfo2'),
                meta: {
                    title: '专家信息',
                    // icon: 'el-icon-s -help'
                }
            },
            {
                path: 'subjectManage',
                name: 'subjectManage',
                component: () => import('@/views/basicInfo/SubjectManage'),
                meta: {
                    title: '学科管理',
                    // icon: 'el-icon-s -help'
                }
            },
        ]
    },
    {
        path: '/statistic',
        component: Layout,
        name: 'statistic',
        // redirect: '/schoolCustom/index',
        meta: {
            title: '数据统计',
            icon: 'el-icon-s-data'
        },
        children: [{
            path: 'p2p_V2',
            name: 'p2p_V2',
            component: () => import('@/views/statistic/p2p_V2'),
            meta: {
                title: '送审统计',
                // icon: 'el-icon-school'
            }
        },

            // {
            //     path: 'expert',
            //     name: 'statisticExpert',
            //     component: () => import('@/views/statistic/expert'),
            //     meta: {
            //         title: '专家统计',
            //         // icon: 'el-icon-s-help'
            //     }
            // },
            // {
            //   path: 'review',
            //   name: 'statisticreview',
            //   component: () => import('@/views/statistic/expert'),
            //   meta: {
            //     title: '评审统计',
            //     // icon: 'el-icon-s-help'
            //   }
            // }
        ]
    },
    // {
    //   path: '/visualization',
    //   name: 'visualization',
    //   children: [{
    //       path: 'm',
    //       name: 'm',
    //       redirect: '/monitor',
    //       meta: {
    //         title: '数据可视化',
    //         icon: 'el-icon-s-promotion'
    //       }
    //     },
    //   ]
    // }


    // {
    //   path: '/logs',
    //   component: Layout,
    //   name: 'logs',
    //   // redirect: '/schoolCustom/index',
    //   meta: {
    //     title: '日志管理',
    //     icon: 'el-icon-s-data'
    //   },
    //   children: [{
    //       path: 'index',
    //       name: 'paperPath',
    //       component: () => import('@/views/logs/paperPath'),
    //       meta: {
    //         title: '日志管理',
    //         // icon: 'el-icon-school'
    //       }
    //     },
    //   ]
    // }
]

// 定义创建路由函数
//
// const createRoute = () => new VueRouter({
//   scrollBehavior: () => ({
//     y: 0
//   }),
//   routes: staticRoutes.concat(asyncRoutes)
// })
const createRoute = () => new VueRouter({
    scrollBehavior: () => ({
        y: 0
    }),
    routes: staticRoutes
})

// const router = new VueRouter({
//   constantRoutes
// })

// 调用创建路由函数
const router = createRoute()

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export function resetRouter() {
    const newRouter = createRoute()
    console.log(newRouter.matcher)
    router.matcher = newRouter.matcher // reset router
}

export default router