const state = {
    rule:{
        insertModel: false
    },
    institution:{
        first:'1',
        second:'11'
    }
}
const mutations = {
    toggle_ruleInsertModel: (state, value) => {
        state.rule.insertModel = value;
    },
    toggleInstitution:(state,value)=>{
        state.institution = value;
    }
}
const actions = {
    toggle_ruleInsertModel({
        commit
    }, {
        value
    }) {
        commit('toggle_ruleInsertModel', value)
    },
    toggleInstitution({commit},value)
    {
        commit('toggleInstitution',value);
    }
}

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions
}