 export default {
    state:{
        titleMap:{
            '0':'副高级',
            '1':'正高级',
            '2':'中级',
            '3':'其他',
            '副高级':'0',
            '正高级':'1',
            '中级':'2',
            '其他':'3',
        },
        typeMap:{
            '0':'硕士生导师',
            '1':'博士生导师',
            '2':'其他',
            '硕士生导师':'0',
            '博士生导师':'1',
            '其他':'2'
        },
        patternMap:{
            '0':'指定学科',
            '1':'关联学科',
            '2':'全部学科',
            '指定学科':'0',
            '关联学科':'1',
            '全部学科':'2'
        },
        stateMap:{
            '0':'待送审',
            '1':'送审中',
            '2':'评审中',
            '3':'已拒绝',
            '4':'已过期',
            '5':'已提交',
            '6':'已撤回',
            '7':'已撤销',
            '8':'待复审',
            '未送审':'0',
            '送审中':'1',
            '接审':'2',
            '拒审':'3',
            '过期':'4',
            '送审完成':'5',
            '已撤回':'6',
            '撤销':'7',
            '复审':'8'
        },
        mailMap:{
            200:'接受成功',
            201:'拒绝成功',
            202:'拒绝后接受',
            203:'拒绝后再拒绝',
            204:'过期再接受',
            205:'过期再拒绝',
            206:'提交后拒绝',
            207:'接受再拒绝',
            0:'链接验证失败',
        },
        marks:{
            "☑-Word":'<w:sym w:font="Wingdings 2" w:char="F052"/>',
            "☑-Wps":'☑',
            "√":"√",
            "○":"○",
            "□-Word":'<w:sym w:font="Wingdings 2" w:char="F0A3"/>',
            "□-Wps":'□',
            "Χ":"Χ",
            "空白":'',
            '空格':' '
        }
    }
}