import { asyncRoutes } from "@/router";

const state = {
    isChangeDiaVis:false,
    isLogin: 1,
    info:{
        name:'',
        institution:'',

        // 服务器返回的菜单信息
        routes:[],
        // 角色信息
        roles:[],
        // 按钮权限
        buttons:[],
    },
    // 对比之后要展示的路由
    resultAsyncRoutes:[]
};
const mutations = {
    changeIsChangeDiaVis:(state, isChangeDiaVis)=>{
        state.isChangeDiaVis = isChangeDiaVis;
    },changeIsLogin(state, isLogin) {
        state.isLogin = isLogin;
    },
    changeInfo(state, value) {
        state.info = value;
    },
    changeResultAsyncRoutes(state, value){
        state.resultAsyncRoutes = value;
    }
};
const actions = {
    changeIsChangeDiaVis({commit}, isChangeDiaVis) {
        commit('changeIsChangeDiaVis', isChangeDiaVis);
    },
    changeIsLogin(context, isLogin) {
        context.commit('changeIsLogin', isLogin);
    },
    changeInfo(context, value) {
        context.commit('changeInfo', value);
        // console.log(context);
    },
    changeResultAsyncRoutes(context, value){
        context.commit('changeResultAsyncRoutes',value);
    }
};

export const computedAsyncRoutes = (asyncRoutes,routes)=>{
    return asyncRoutes.filter(item=>{
        if(routes.indexOf(item.name) != -1){
            if(item.children && item.children.length){
                item.children = computedAsyncRoutes(item.children, routes);
            }
            return true;
        }
    })
}

export default {
    namespaced: true,
    state:state,
    mutations:mutations,
    actions:actions,
    // computedAsyncRoutes
}