<template>
    <div v-if='!item.hidden'>
        <!-- 无儿子路由 -->
        <template
            v-if='hasOneShowingChild(item.children,item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&!item.alwaysShow'>
            <app-link v-if='onlyOneChild.meta' :to="resolvePath(onlyOneChild.path)">
                <el-menu-item :index='resolvePath(onlyOneChild.path)'>
                    <item :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
                        :title="onlyOneChild.meta.title"> </item>
                </el-menu-item>
            </app-link>
        </template>
        <!-- 带儿子的路由 -->
        <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
            <template slot="title">
                <item v-if='item.meta' :icon="item.meta && item.meta.icon" :title="item.meta.title"> </item>
            </template>
            <sidebar-item v-for='child in item.children' :key="child.path" :is-nest="true" :item='child'
                :basePath='resolvePath(child.path)'>
            </sidebar-item>
        </el-submenu>
    </div>
</template>

<script>
    import path from 'path'
    import AppLink from './Link.vue'
    import Item from '@/layout/components/SideBar/Item'
    export default {
        name: 'SidebarItem',
        data() {
            this.onlyOneChild = null;
            return {
                
            }
        },
        props: {
            item: {
                type: Object,
                require: true
            },
            basePath: {
                type: String,
                require: true
            }
        },
        created() {
            // console.log(this.item);
        },
        methods: {
            hasOneShowingChild(children = [], parent) {
                const showingChildren = children.filter(item => {
                    if (item.hidden) {
                        return false;
                    } else {
                        this.onlyOneChild = item;
                        return true;
                    }
                })
                if (showingChildren.length === 1) {
                    return true;
                }
                if (showingChildren.length === 0) {
                    this.onlyOneChild = {
                        ...parent,
                        path: '',
                        noShowingChildren: true
                    }
                    return true;
                }
                return false;
            },
            isExternal(path) {
                return /^(https?:|mailto:|tel:)/.test(path)
            },
            resolvePath(routePath) {
                if (this.isExternal(routePath)) {
                    return routePath;
                }
                if (this.isExternal(this.basePath)) {
                    return routePath;
                }
                return path.resolve(this.basePath, routePath);
            }
        },
        components: {
            AppLink,
            Item
        }
    }
</script>

<style scoped>

</style>