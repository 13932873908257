import Cookies from 'js-cookie'

const state={
    sidebar:{
        opend:Cookies.get('sidebarStatue') ? !!+Cookies.get('sidebarStatue') : true,
        withoutAnimation:false
    }
}

const mutations={
    TOGGLE_SIDEBAR:state => {
        state.sidebar.opend = !state.sidebar.opend;
        state.sidebar.withoutAnimation = false;
        if(state.sidebar.opend)
        {
            Cookies.set('sidebarStatue',1);
        }
        else
        {
            Cookies.set('sidebarStatue',0);
        }
    },
    CLOSE_SIDEBAR:(state,withoutAnimation) => {
        Cookies.set('sidebarStatue',0);
        state.sidebar.opend = false;
        state.sidebar.withoutAnimation = withoutAnimation;
    }
}

const actions={
    toggleSidebar({commit})
    {
        commit('TOGGLE_SIDEBAR')
    },
    closeSidebar({commit},{withoutAnimation})
    {
        commit('TOGGLE_SIDEBAR',withoutAnimation)
    },
}

export default {
    namespaced: true,
    state:state,
    mutations:mutations,
    actions:actions
}
