import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// 引入elementui
import Element from "element-ui";
import "./styles/element-variables.scss";
Vue.use(Element);

// 引入vxe-table
import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
Vue.use(VXETable);

// 引入NProgress
// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
// NProgress.configure({ showSpinner: false });

// 引入自定义样式
import "./styles/index.scss";
// import '@/icons'

// 将自定义的api请求挂载到vue原型中
import api from "./api/index";
Vue.prototype.$api = api;

// 引入qs
import qs from "qs";

import permission from "./permission";

// router.beforeEach(async(to,from,next) => {
//   NProgress.start();
//   next();
//   NProgress.done();
// })

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
