import schoolCustom from '@/api/schoolCustom'
import reviewCustom from '@/api/reviewCustom'
import authority from './authority'
import sendingP2p from './sendingP2p'
import user from './user'
import financial from './financial'
import reviewCheck from './reviewCheck'
import basicInfo from './basicInfo'
import account from './account'
import batchManagement from "@/api/batchManagement"
import visual from './visual'

// 对外暴露
export default {
    schoolCustom,
    reviewCustom,
    authority,
    sendingP2p,
    user,
    financial,
    reviewCheck,
    basicInfo,
    account,
    batchManagement,
    visual
}