import h from '@/api/http/httpVisual'

const http = h.http;
const get = h.get;
const post = h.post;

const visual = {
    getAllBatch(){
        return get('/batch/getAllBatch');
    },
    getAllInstitution(){
        return get('/batch/getAllInstitution');
    },
    getBatchYear(){
        return get('/batch/getBatchYear');
    },
    getMatchRes(pageNum, pageSize){
        // ?pageNum=" + this.table1_pageNum + "&pageSize=" + this.table1_pageSize
        return get('/getMatchRes?pageNum=' + pageNum + '&pageSize=' + pageSize);
    },
    getPaperCountPerMonth(){
        return get('/getPaperCountPerMonth');
    },

    getSubjectDistribution(){
        return get('/getPaperCountByMajor');
    },
    getExpertAndResult(){
        return get('/expertAndResult');
    },
    getAllSubmit(){
        return get('/result/getSubmit');
    },
    getAllOrg(){
        return get('/getAllOrg');
    },
    getMap(){
        return get('/getMap');
    },
    getPaperCountPerMonthScreen(institutionId, batchId, year){
        return get('/getPaperCountPerMonthScreen?' + 'institutionId=' + institutionId + '&batchId=' + batchId + '&year=' + year);
    }
}

export default visual