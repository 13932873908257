<template>
    <!-- 动态组件及绑定属性 is绑定组件  v-bind 绑定组件属性 -->
    <component :is="type" v-bind="comProps(to)">
        <slot></slot>
    </component>
</template>

<script>
    export default {
        name: 'AppLink',
        props:{
            to:{
                type:String,
                require:true
            }        
        },

        computed: {
            isExternal(){
                return this.External(this.to);
            },
            type() {
                if (this.isExternal) {
                    return 'a'
                }
                return 'router-link'
            },
        },
        methods: {
            External(path) {
                return /^(https?:|mailto:|tel:)/.test(path)
            },
            comProps(to)
            {
                if(this.isExternal)
                {
                    return {
                        href:to,
                        target:'_blank',
                        rel:'noopener'
                    }
                }
                return {
                    to:to
                }
            }
        },
        components: {

        }
    }
</script>

<style scoped>

</style>