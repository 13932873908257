<template>
<!-- 标签区域 -->
    <div>
        <!-- tagsView -->
    </div>
</template>

<script>
    export default {
        name: 'TagsView',
        data() {
            return {

            }
        },
        methods: {

        },
        components: {

        }
    }
</script>

<style scoped>

</style>